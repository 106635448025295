import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
} from "@mui/material";
import {useEffect, useState} from "react";
import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import * as XLSX from 'xlsx';
import sendRequest from "../services/sendRequest";
import MoonLoader from "react-spinners/MoonLoader";

const UnknownDealUpload =({open, close, selected, setRefresh}) => {

const { enqueueSnackbar } = useSnackbar()
const [items, setItems] = useState([]);
const [formData, setFormData] = useState({});
const [fullWidth, setFullWidth] = React.useState(true);
const [maxWidth, setMaxWidth] = React.useState('sm');
let [loading, setLoading] = useState(false);
let [color, setColor] = useState("#000000");
const page ="deals";
const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
const handleClose = () => {
    close();
  };

  useEffect(() => {
  });

  // handle file upload
  const handleFileUpload = e => {
    setLoading(true)
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const Papa = require('papaparse');
      const fileData = XLSX.utils.sheet_to_csv(ws, { header: 1 });

      // Parse the CSV data
      const parsedData = Papa.parse(fileData, {
        header: false, // Set to true if the first row contains headers
      });

      // Initialize an array to hold column-wise data
      let columns = [];
      const items = [];

      // Iterate through each row
      parsedData.data.forEach((row, rowIndex) => {
        // Iterate through each cell and organize them into columns
        row.forEach((cell, colIndex) => {
          // Initialize the column array if it doesn't exist
          if (!columns[colIndex]) {
            columns[colIndex] = [];
          }
          // Add the cell to the appropriate column
          columns[colIndex][rowIndex] = cell;
        });
        // Create an object for each row
        const newObject = {
          custom_id_stem: row[0], // Assuming the first column is the title
          name: row[1],
          report_emails: row[2],
          report_type: row[3],
          client_rev_share: row[4],          
          // Assuming the second column is the custom_id
          // Add more fields as needed
        };
        items.push(newObject);
      });
      console.log(items);
      if (items[0]) {
        try {      
          const response = await sendRequest({
            requestType: "post",
            endpoint: `${page}/custom_bulk_create/`,
            requestData: items,
          });
      
          if (response.status === 200) {
            setItems([]);
            if(response.data.deal_not_available == true) {
              partial_handleSuccess(response.data.message);
            } else {
              handleSuccess(response.data.message);
            }
            handleClose();
          } else {
            handleError();
          }
      
        } catch (error) {
          console.error("An error occurred:", error);
          handleError();
        } finally {
          setItems([]);
          setLoading(false);
          handleClose();
        }
      } else {
        setItems([]);
        handleErrorForMoreThanTwoCoulumnsInFile();
        handleClose();
      }
    };
    reader.readAsBinaryString(file);
  }

  const handleError = () => {
    enqueueSnackbar('There is an Error with the file, Please Check the .csv file and Reupload it!', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 5000,
    });
  };

  const handleErrorForMoreThanTwoCoulumnsInFile = () => {
    enqueueSnackbar('The file should contains only 2 Columns [Title and Custom ID] with no headers', {
      variant: 'error',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 10000,
    });
  };

  const partial_handleSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: 'warning',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 3000,
    });
  };

  const handleSuccess = (message) => {
    enqueueSnackbar(message, {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: 3000,
    });
  };
  return (
    <Dialog
    open={open}
    onClose={() => {
      close();
    }}
    >
      <DialogContent>
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={handleClose}
          >
            <MoonLoader
              color={color}
              loading={loading}
              cssOverride={override}
              size={40}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <DialogTitle sx={{ textAlign: 'center' }}>Upload CSV</DialogTitle>
            <DialogContent sx={{ marginBottom: 2 }}>
              <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
              Upload a CSV file to Create the new Deals!
              </DialogContentText>
              <DialogContentText sx={{ textAlign: 'center' , whiteSpace: 'normal', wordWrap: 'break-word', marginBottom: 2}}>
              <b>The .CSV file with no headers should contain the following columns in the specified order: 1) Custom ID Stem, 2) Deal Name, 3) Report Email Addresses, 4) Report Type, and 5) Client Share.</b>
              </DialogContentText>
              <Box
                noValidate
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  m: 'auto',
                  width: 'fit-content',
                }}
              >        
              <FormControl sx={{ mt: 2, minWidth: 120 }}>              
                <input type="file" accept=".csv,.xlsx,.xls" onChange={handleFileUpload}/>
              </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
      </DialogContent>
    </Dialog>
  );
};

export default UnknownDealUpload;
